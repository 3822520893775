import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent} from '@mui/material';
import {useAppDispatch} from '../../app/hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment/moment';
import {getRecentPricesGraphAsync, getSecurityEPSGraphAsync} from '../../store/securities/securitiesSlice';
import Loader from '../../components/loader';
import {useParams} from 'react-router-dom';
import {ValuationsPageRouteParams} from './index';
import useSecurity from '../../hooks/use-security';

const ValuationsChart: React.FC = () => {
    const [chartOptions, setChartOptions] = React.useState({});
    const dispatch = useAppDispatch();
    const {securityId, userId} = useParams<ValuationsPageRouteParams>();
    const [epsIndex, setEpsIndex] = React.useState<number>(1);
    const {activeSecurity, epsGraphData, recentPriceData} = useSecurity();
    const [selectedEpsDate, setSelectedEpsDate] = React.useState<undefined | string>(undefined);

    React.useEffect(() => {
        if (securityId && userId) {
            dispatch(getRecentPricesGraphAsync({securityId}));
            dispatch(getSecurityEPSGraphAsync({securityId, userId}));
        }
    }, [dispatch, securityId, userId]);

    React.useEffect(() => {
        if (epsGraphData && (!selectedEpsDate || epsGraphData.epsDates.indexOf(selectedEpsDate) === -1)) {
            // if no eps date is selected, or the selected date is not valid, select the first valid date.
            setSelectedEpsDate(epsGraphData.epsDates[0]);
        }
    }, [epsGraphData, selectedEpsDate]);

    React.useEffect(() => {
        if (!epsGraphData || !epsGraphData.graphData) {
            return;
        }

        const options = {
            chart: {
                type: 'spline',
            },
            title: {
                text: `EPS Comparison (FYE ${selectedEpsDate})`
            },
            data: {
                dateFormat: 'YYYY/mm/dd'
            },
            tooltip: {
                shared: true,
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            xAxis: {
                categories: epsGraphData.graphData.dates.map((v: any) => {
                    return v
                }),
                labels: {
                    formatter: function (label: any): string {
                        const date = moment(label.value);
                        const dateLabel = date.format('YYYY-MM-DD');
                        if (dateLabel === date.endOf('month').format('YYYY-MM-DD')) {
                            return dateLabel;
                        }
                        return '';
                    },
                    // step: 1,
                    allowOverlap: false,
                }
            },
            yAxis: [{ // Primary yAxis
                title: {
                    text: 'EPS',
                },

            }, { // Secondary yAxis
                title: {
                    text: 'Share Price',
                },
                opposite: true
            }],
            series: [
                {
                    name: `EPS 0 ${epsGraphData.epsDates[0]}`,
                    data: epsGraphData.graphData[`eps-${epsGraphData.epsDates[0]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[0] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS 0 ${epsGraphData.epsDates[0]}`,
                    data: epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[0]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[0] || !selectedEpsDate,
                },
                {
                    name: `EPS 1 ${epsGraphData.epsDates[1]}`,
                    data: epsGraphData.graphData[`eps-${epsGraphData.epsDates[1]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[1] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS 1 ${epsGraphData.epsDates[1]}`,
                    data: epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[1]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[1] || !selectedEpsDate,
                },
                {
                    name: `EPS 2 ${epsGraphData.epsDates[2]}`,
                    data: epsGraphData.graphData[`eps-${epsGraphData.epsDates[2]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[2] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS 2 ${epsGraphData.epsDates[2]}`,
                    data: epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[2]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[2] || !selectedEpsDate,
                },
                {
                    name: `EPS 3 ${epsGraphData.epsDates[3]}`,
                    data: epsGraphData.graphData[`eps-${epsGraphData.epsDates[3]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[3] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS 3 ${epsGraphData.epsDates[3]}`,
                    data: epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[3]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[3] || !selectedEpsDate,
                },
                {
                    name: `EPS 4 ${epsGraphData.epsDates[4]}`,
                    data: epsGraphData.graphData[`eps-${epsGraphData.epsDates[4]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[4] || !selectedEpsDate,
                },
                {
                    name: `CONS EPS 4 ${epsGraphData.epsDates[4]}`,
                    data: epsGraphData.graphData[`cons-eps-${epsGraphData.epsDates[4]}` as keyof object].map((v: any) => v),
                    visible: selectedEpsDate === epsGraphData.epsDates[4] || !selectedEpsDate,
                },
                {
                    name: 'Price',
                    data: epsGraphData.graphData.prices.map((v: any) => v),
                    yAxis: 1
                },
            ]
        };

        setChartOptions(options);
    }, [activeSecurity?.epsDateRange, epsGraphData, epsIndex, selectedEpsDate, setChartOptions]);

    const onSetSelectedEpsDate = (e: SelectChangeEvent) => {
        setSelectedEpsDate(e.target.value);
    }

    return (
        <Paper elevation={3} sx={{px: 3, py: 5}}>
            <Loader loading={!epsGraphData} message={"Loading chart..."}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        {
                            selectedEpsDate && (
                                <FormControl>
                                    <InputLabel id="fye-selector-label" shrink>FYE</InputLabel>
                                    <Select
                                        labelId="fye-selector"
                                        id="fye-selector-label"
                                        label="FYE"
                                        onChange={onSetSelectedEpsDate}
                                        size="small"
                                        value={selectedEpsDate}
                                        displayEmpty
                                        notched
                                    >
                                        {
                                            epsGraphData?.epsDates && epsGraphData.epsDates.map((v: any) => (
                                                <MenuItem key={v} value={v} selected={selectedEpsDate === v}>
                                                    {v}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            immutable={false}
                        />
                    </Grid>
                </Grid>
            </Loader>
        </Paper>

    )
}

export default ValuationsChart;