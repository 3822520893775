import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from '@mui/material';
import FTextField from '../../forms/text-field';
import {SubmitHandler, useForm} from 'react-hook-form';
import FSelect from '../../forms/select';
import useSectors from '../../../hooks/use-sectors';
import {useAppDispatch} from '../../../app/hooks';
import {createSecurityAsync} from '../../../store/securities/securitiesSlice';

export interface NewSecurityDialogProps {
    onClose: () => void;
    open: boolean;
}

const NewSecurityDialog: React.FC<NewSecurityDialogProps> = ({onClose, open}) => {
    const dispatch = useAppDispatch();
    const {control, handleSubmit, formState: {errors}} = useForm();
    const {sectors} = useSectors();

    const onSubmit: SubmitHandler<any> = async (security: any) => {
        dispatch(createSecurityAsync({
            bloombergCode: security.bloombergCode,
            longCompanyName: security.longCompanyName,
            sectorClassification: security.sectorClassification,
        }));
    };

    const sectorOptions = React.useCallback(() => {
        return sectors.map((sector) => {
            return {value: sector.id, label: sector.name}
        });
    }, [sectors]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Add New Security
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant={"body2"}>
                            Complete the fields below to add a new security to Lumina.
                        </Typography>
                    </DialogContentText>


                    <Grid container>
                        <Grid item xs={12}>
                            <FTextField
                                control={control}
                                name="bloombergCode"
                                label="Bloomberg Code"
                                textAlign="left"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FTextField
                                control={control}
                                name="longCompanyName"
                                label="Company Name"
                                textAlign="left"
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FSelect
                                control={control}
                                name="sectorClassification"
                                label="Sector"
                                options={sectorOptions()}
                                required={true}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="contained" color="primary" type="submit">
                            Submit
                        </Button>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default NewSecurityDialog;