import React, {useCallback} from 'react';
import PageContainer from '../../components/page-container';
import {Box, Button, Divider, Grid, Paper, Typography} from '@mui/material';
import SecuritiesTable from './securities-table';
import PageHeader from '../../components/page-header';
import DashboardToolbar from './dashboard-toolbar';
import {useLocation, useNavigate} from 'react-router-dom';
import StyledBreadcrumbs from '../../components/styled-breadcrumbs';
import UserTabs from '../../components/user-tabs';
import SecuritiesTableConfigModal from '../../components/securities-table-config-modal';
import SettingsIcon from '@mui/icons-material/Settings';
import useSecurity from '../../hooks/use-security';
import {AddOutlined, KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {acknowledgeSecurityCreated, fetchAllSecuritiesAsync} from '../../store/securities/securitiesSlice';
import {useAppDispatch} from '../../app/hooks';
import Loader from '../../components/loader';
import useUsers from '../../hooks/use-users';
import NewSecurityDialog from '../../components/dialogs/new-security-dialog';
import ExistingSecurityDialog from '../../components/dialogs/existing-security-dialog';

const Dashboard: React.FC = () => {
    const [search, setSearch] = React.useState('');
    const [sector, setSector] = React.useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [securitiesTableConfigModalOpen, setSecuritiesTableConfigModalOpen] = React.useState(false);
    const {activeOwner, securitiesLoading, listedSecurities, securityCreated, existingSecurity} = useSecurity();
    const [fetchSecuritiesPromise, setFetchSecuritiesPromise] = React.useState<any>();
    const dispatch = useAppDispatch();
    const {isLoadingUsers} = useUsers();
    const [newSecurityDialogOpen, setNewSecurityDialogOpen] = React.useState<boolean>(false);
    const [selectedExchanges, setSelectedExchanges] = React.useState<string[]>([]);

    /**
     * If a next param is found in location, decode it and navigate to it.
     */
    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const path = searchParams.get('next');

        if (path) {
            try {
                navigate(decodeURIComponent(path));
            } catch (e) {
                console.error('Could not decode path: ', path);
            }
        }
    }, [location, navigate]);

    const loadUserSecurities = useCallback(() => {
        if (!activeOwner) {
            return;
        }

        // Check if a promise exists and abort it directly within this function
        // This is for when users are switching between tabs, to make sure we don't show the result of
        // a previous promise by accident.
        if (fetchSecuritiesPromise) {
            fetchSecuritiesPromise.abort();
        }

        setFetchSecuritiesPromise(dispatch(fetchAllSecuritiesAsync(activeOwner)));
    }, [activeOwner, fetchSecuritiesPromise, dispatch]);

    React.useEffect(() => {
        loadUserSecurities();
        // Do not add the loadUserSecurities dependency, it'll cause infinite re-rendering.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOwner]);

    React.useEffect(() => {
        if (securityCreated) {
            setNewSecurityDialogOpen(false);
            dispatch(acknowledgeSecurityCreated());
        }
    }, [dispatch, securityCreated]);

    React.useEffect(() => {
        if (!!existingSecurity) {
            setNewSecurityDialogOpen(false);
        }
    }, [existingSecurity])

    const dashboardActions = () => {
        return (
            <>
                <Button variant="contained" color="primary" size="small" onClick={() => setNewSecurityDialogOpen(true)}>
                    <AddOutlined/> Add New
                </Button>
                <NewSecurityDialog open={newSecurityDialogOpen} onClose={() => setNewSecurityDialogOpen(false)}/>
                <ExistingSecurityDialog />
            </>
        );
    }

    const toggleExchanges = (exchanges: string[]) => {
        setSelectedExchanges(exchanges);
    }

    return (
        <PageContainer pageTitle="Dashboard" browserTabTitle="Dashboard">
            <PageHeader title="Dashboard" actions={dashboardActions()}>
                <DashboardToolbar
                    setSearchText={setSearch}
                    onFilterSector={setSector}
                    selectedSector={sector}
                    onSetSelectedExchanges={toggleExchanges}
                />
            </PageHeader>
            <Loader message="Please wait..." loading={securitiesLoading || isLoadingUsers}>
                <Paper elevation={3} sx={{mb: 5}}>
                    <SecuritiesTableConfigModal open={securitiesTableConfigModalOpen}
                                                onClose={() => setSecuritiesTableConfigModalOpen(false)}
                                                onSave={() => setSecuritiesTableConfigModalOpen(false)}/>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <UserTabs/>
                        <Button onClick={() => setSecuritiesTableConfigModalOpen(true)}>
                            <SettingsIcon/>
                        </Button>
                    </Box>
                    <Divider/>
                    <SecuritiesTable search={search} sector={sector} securitiesList={listedSecurities}
                                     selectedExchanges={selectedExchanges}/>
                </Paper>
            </Loader>

            <StyledBreadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Dashboard</Typography>
            </StyledBreadcrumbs>
        </PageContainer>
    )
}

export default Dashboard;