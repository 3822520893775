import {User} from '../models/user';
import {Permission} from '../models/permission';

export const getUserByIdFromList = (userId: string, users: User[]): User => {
    const result = users.find(u => u.id === userId);
    if (!result) {
        throw new Error(`Could not find user with id ${userId}`);
    }

    return result;
}

export const formatNumber = (num?: number, decimals: number = 2, sep: string = ' ', suffix = ''): string => {
    if (num === undefined || num === null) {
        return '';
    }
    const formattedNumber =  (+num).toFixed(decimals).replace(/(\.0+|0+)$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, sep);
    return `${formattedNumber}${suffix}`;
}

export const doesUserHavePermission = (user: User|undefined, permissionCode: string) => {
    return user?.groups.some(
        group => group.permissions.some(
            (permission: Permission) => permission.codename === permissionCode
        )
    );
}